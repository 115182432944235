import React,{useEffect} from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles} from "@material-ui/core";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ALink from '../../common/ALink'

gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
    Button:{
        position:"fixed",
        bottom:"50px",
        right:"10px",
        [theme.breakpoints.up("sm")]:{
          right:"20px",
          fontSize:"1.3em",
        },
        [theme.breakpoints.up("md")]:{
          right:"20px",
          fontSize:"1.4em",
        }
    }
}));

export default function DisableElevation() {
    const classes = useStyles();
    const fadeIn = () => {
        return gsap.to("#scrollButton", {
          duration: 0.7,
          autoAlpha: 1,
          y: 0,
          ease: 'power4.out'
        })
      }
      const fadeOut = () => {
        return gsap.to("#scrollButton", {
          duration: 0.7,
          autoAlpha: 0,
          y: 0,
          ease: 'power4.out'
        })
      }
      
      useEffect (() => {
        ScrollTrigger.create({
          trigger: '.entry-button-js',
          start: 100,
          end: 'bottom-=50 bottom',
          onEnter: () => fadeIn(),
          onEnterBack: () => fadeIn(),
          onLeave: () => fadeOut(),
          onLeaveBack: () => fadeOut(),
        })
      },[])
     
  return (
    <ALink to="/topics">
    <Button variant="contained" color="primary" id="scrollButton" className={classes.Button} >
        Học Ngay
    </Button>
    </ALink>
  );
}