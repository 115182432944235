import React from "react"
import Header from "../sections/Header"
import Button from "../sections/Button"
import NavBar from "../sections/NavBar"
import Share from "../sections/Share"
import Characteristic from "../sections/Characteristic"
import Medical from "../sections/Medical"
import Mechanical from "../sections/Mechanical"

import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0",
    backgroundColor: "#f5f5f5",
  },
}))

const LandingPage = ({ data }) => {
  const classes = useStyles()
  return (
    <div className={classes.root} style={{ overflow: "hidden" }}>
      <NavBar />
        <div>
          <Header data={data.header}/>
          <Characteristic data={data.characteristic}/>
          <Medical data={data.medical}/>
          <Mechanical data={data.mechanical}/> 
          <Share/>
        </div>
        <Button/>
    </div>
  )
}
export default LandingPage
