import React from "react";
import Logo from "../../../data/menu-header/media/world-logo.png"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import ALink from "../../common/ALink"
import clsx from "clsx"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "react-scroll"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundImage: "linear-gradient(to right, #5da5ee, #2278CF)",
    minHeight:"64px"
  },
  logo: {
    marginTop: 5,
    marginLeft: 10,
    width: 70,
    [theme.breakpoints.down("xs")]: {
      marginLeft: -8,
      width: 50,
    },
  },
  menuheader: {
    display: "flex",
    flexGrow: 1,
    marginLeft: "25%",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  top: {
    cursor: "pointer",
    "&:hover": {
      color: "#E9FC06",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "-5%",
      fontSize: "0.6em",
    },
  },
  slider: {
    cursor: "pointer",
    "&:hover": {
      color: "#E9FC06",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "-5%",
      fontSize: "0.6em",
    },
  },
  characteristic: {
    cursor: "pointer",
    "&:hover": {
      color: "#E9FC06",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "-5%",
      fontSize: "0.6em",
    },
  },
  feature: {
    cursor: "pointer",
    "&:hover": {
      color: "#E9FC06",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "-5%",
      fontSize: "0.6em",
    },
  },
  comment: {
    cursor: "pointer",
    "&:hover": {
      color: "#E9FC06",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "-5%",
      fontSize: "0.6em",
    },
  },
  share: {
    cursor: "pointer",
    "&:hover": {
      color: "#E9FC06",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "-5%",
      fontSize: "0.6em",
    },
  },
}))
export default function NavBar() {
  const classes = useStyles()
  return (
    <header className={classes.root}>
      <AppBar
         position="fixed"
         className={clsx(classes.appBar)}
      > 
      <Toolbar>
        <div>
          <ALink to="/home">
            <img src={Logo} alt="" className={classes.logo} />
          </ALink>
        </div>
        <div className={classes.menuheader}>
          <Typography variant="h6"><Link  to="header" spy={true} smooth={true} offset={-60} duration= {800} className={classes.top}>Top</Link></Typography>
          <Typography variant="h6"><Link  to="characteristic" spy={true} smooth={true} offset={-135} duration= {800} className={classes.slider}>IT</Link></Typography>
          <Typography variant="h6"><Link  to="medical" spy={true} smooth={true} offset={-70} duration= {800} className={classes.characteristic}>Y tế - Điều dưỡng</Link></Typography>
          <Typography variant="h6"><Link  to="mechanical" spy={true} smooth={true} offset={-135} duration= {800} className={classes.feature}>Cơ khí</Link></Typography>
          <Typography variant="h6"><Link  to="share" spy={true} smooth={true} offset={-55} duration= {800} className={classes.share}>Share</Link></Typography>
      </div>
      </Toolbar>
      </AppBar>
    </header>
  )
}
