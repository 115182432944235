import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Image from "../../common/Image";
import Button from "@material-ui/core/Button";
import ALink from "../../common/ALink";

const useStyles = makeStyles((theme) => ({
  root:{
    fontFamily:"Lato",
    display:"inline-block",
    backgroundColor:"#F1F1F1",
    textAlign:"center",
    margin:"5% auto",
    [theme.breakpoints.down("xs")]:{
      flexDirection: "column",
    },
  },
  head:{
    textAlign:"left",
    marginTop: "7%",
    marginLeft: "11%",
    width:"70%",
    [theme.breakpoints.down("xs")]:{
      width:"76%",
      marginTop: "10%",
    },
  },
  l2:{
    width:"45%",
    display:"flex",
    marginBottom: "-55px",
    [theme.breakpoints.down("sm")]:{
      marginBottom: "0",
    },
    [theme.breakpoints.down("xs")]:{
      width:"100%",
    },
  },
  label:{
    width:"100%",
    display:"flex",
    marginLeft: "10%",
    marginTop: "-10px",
    color:"#FD4967",
    fontSize:"30px",
    [theme.breakpoints.down("sm")]:{
      fontSize:"20px",
    },
    [theme.breakpoints.down("xs")]:{
      marginTop: "-5px",
    },
  },
  title:{
    textAlign:"right",
    width:"110%",
    marginBottom: "3%",
    color:"#1350A0",
    fontSize:"40px",
    [theme.breakpoints.down("sm")]:{
      textAlign:"left",
      width:"100%",
      marginBottom: "0",
      fontSize:"30px",
    },
    [theme.breakpoints.down("xs")]:{
      
    },
  },
  description:{
    color:"#636363",
    fontSize:"20px",
    width:"55%",
    marginLeft: "56%",
    [theme.breakpoints.down("sm")]:{
      fontSize:"15px",
      marginLeft: "0",
    },
    [theme.breakpoints.down("xs")]:{
      marginLeft: "0",
      width:"100%",
    },
  },
  container:{
    display:"flex",
    margin:"5% auto",
    width:"80%",
    [theme.breakpoints.down("xs")]:{
      display:"inline-block",
    },
  },
  img:{
    display:"flex",
    width:"100%",
    "& img":{
      position:"static !important"
    },
    [theme.breakpoints.down("sm")]:{

    },
    [theme.breakpoints.down("xs")]:{
     
    },
  },
  imgCardChild:{
    width:"95%",
    margin:"3%",
    [theme.breakpoints.down("xs")]:{
    
    },
  },
  button:{
    marginTop: "-150px",
    color:"#0367CC",
    backgroundColor: "#F9CE23",
    transition:"0.5s",
    "&:hover":{
        color:"white",
        backgroundColor:"#0367CC"
    },
    [theme.breakpoints.down("sm")]:{
      marginTop: "5%",
    },
  },
  alink:{
    textDecoration:"none"
  }
 
}));

export default function Medical({data}) {
  const classes = useStyles();
  return (
    <div className={classes.root} id="medical">
         <div className={classes.head}>
            <div className={classes.l2}>
                <hr width="20%" size="2" color="#636363" align="left" margin="0"/>  
                <div className={classes.label} gutterBottom variant="h3" >
                    {data.label}
                </div>
            </div>
            <div className={classes.title} gutterBottom variant="h3" >
                {data.title}
            </div>
            <div className={classes.description}>
                {data.description}
            </div>
            <ALink className={classes.alink} to="/topics/nurse">
                <Button className={classes.button} variant="contained">
                  Học ngay
                </Button>
                </ALink>
        </div>
        <div className={classes.container}>
          {data.listImg.map((listItem, index) => {
                return (
                  <div className={classes.img}  key={index}>
                      <Image {...listItem.img}  className={classes.imgCardChild}/>
                  </div>
                )
              })}
        </div>
    </div>
  );
}