import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Container, Typography } from "@material-ui/core"
import background from "../../../data/landing-pages/media/share.png"
import { FacebookShareButton } from "react-share"
import FacebookIcon from "@material-ui/icons/Facebook"

const useStyles = makeStyles(theme => ({
  share: {
    height: "400px",
    width: "100%",
    backgroundImage: `linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    ), url("${background}")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: " flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  title: {
    fontSize: "50px",
    color: "#2278CF",
    marginBottom: theme.spacing(5),
  },
  description: {
    margin: "auto",
    color: "black",
    marginBottom: theme.spacing(5),
    maxWidth: "70%",
  },
  shareButtonList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: theme.spacing(-2),
  },
  shareButton: {
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
    width: "200px",
    padding: "5px !important",
    border: "2px solid #fff !important",
    borderRadius: "8px !important",
    outline: "none !important",
    marginLeft: theme.spacing(2),
    "& span": {
      fontSize: "1.4em",
      fontWeight: "1000",
      color: "#2278CF",
      marginLeft: theme.spacing(1),
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    fontSize: "35px",
  },
}))

function Share(props) {
  const classes = useStyles()
  return (
    <section className={classes.share} id="share">
      <Container className={classes.container}>
        <Typography
          component="h2"
          variant="h4"
          align="center"
          className={classes.title}
        >
          Share
        </Typography>
        <Typography className={classes.description} align="center">
          Trang Facebook World Japanese Dojo của chúng tôi đã có rất nhiều bài
          viết hay cùng các kiến thức về học tập và đời sống tại Nhật Bản
        </Typography>
        <div className={classes.shareButtonList}>
          <FacebookShareButton
            url={"https://japanese-dojo.world-works.co.jp/"}
            quote={"Học tiếng Nhật tại World Japanese Dojo"}
            hashtag={"#WorldJapaneseDojo"}
            description={"Học tiếng Nhật tại World Japanese Dojo"}
            className={classes.shareButton}
          >
            <FacebookIcon
              style={{ color: "#1777F2" }}
              className={classes.icon}
            />
            <span>Facebook</span>
          </FacebookShareButton>
          {}
        </div>
      </Container>
    </section>
  )
}

export default Share
