import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Image from "../../common/Image";
import Button from "@material-ui/core/Button";
import ALink from "../../common/ALink";


const useStyles = makeStyles((theme) => ({
  root:{
    fontFamily:"Lato",
    display:"flex",
    textAlign:"center",
    margin:"5% auto",
    [theme.breakpoints.down("xs")]:{
      flexDirection: "column",
    },
  },
  head:{
    textAlign:"left",
    marginTop: "5%",
    marginLeft: "11%",
    width:"70%",
    [theme.breakpoints.down("md")]:{
      marginTop: "3%",
    },
    [theme.breakpoints.down("xs")]:{
      width:"76%",
    },
  },
  l2:{
    display:"flex",
    width:"100%",
    [theme.breakpoints.down("md")]:{
      
    },
  },
  label:{
    width:"100%",
    display:"flex",
    marginLeft: "10%",
    marginTop: "-10px",
    color:"#FD4967",
    fontSize:"30px",
    [theme.breakpoints.down("sm")]:{
      fontSize:"20px",
    },
    [theme.breakpoints.down("xs")]:{
      marginTop: "-5px",
    },
  },
  title:{
    color:"#1350A0",
    fontSize:"40px",
    [theme.breakpoints.down("sm")]:{
      fontSize:"30px",
    },
  },
  description:{
    color:"#636363",
    fontSize:"20px",
    [theme.breakpoints.down("sm")]:{
      fontSize:"15px",
    },
  },
  img:{
    width:"100%",
    marginLeft:"10%",
    marginBottom:"5%",
    "& img":{
      position:"static !important"
    },
    [theme.breakpoints.down("sm")]:{
      width:"80%",
    },
    [theme.breakpoints.down("xs")]:{
    
    },
  },
  imgCardChild:{
    width:"75%",
    marginRight:"20%",
    [theme.breakpoints.down("sm")]:{

    },
    [theme.breakpoints.down("xs")]:{
      width:"100%",
      marginRight:"0",
      marginTop:"10%",
    },
  },
  button:{
    marginTop: "5%",
    color:"#0367CC",
    backgroundColor: "#F9CE23",
    transition:"0.5s",
    "&:hover":{
        color:"white",
        backgroundColor:"#0367CC"
    }
  },
  alink:{
    textDecoration:"none"
  }
}));

export default function Mechanical({data}) {
  const classes = useStyles();
  return (
    <div className={classes.root} id="mechanical">
        <div className={classes.head}>
        <div className={classes.l2}>
                <hr width="20%" size="2" color="#636363" align="left" margin="0"/>  
                <div className={classes.label} gutterBottom variant="h3" >
                    {data.label}
                </div>
            </div>
            <div className={classes.title} gutterBottom variant="h3" dangerouslySetInnerHTML={{ __html: data.title.replace(/\n/g, '<br />') }} >
    
            </div>
            <div className={classes.description}>
                {data.description}
            </div>
            <ALink className={classes.alink} to="/topics/machine">
              <Button className={classes.button} variant="contained">
                Học ngay
              </Button>
            </ALink>
        </div>
        <div className={classes.img}>
              <Image {...data.img}  className={classes.imgCardChild}/>
        </div>
    </div>
  );
}
