import React from "react"
import { graphql } from "gatsby"
import withRoot from "../components/shell/withRoot"
import LandingPage from "../components/landingPage/pageContents/LandingPage"
import SEO from "../components/seo"

function LandingPages({ data }) {
  return (
    <React.StrictMode>
      <SEO title="Website học tiếng Nhật Miễn Phí" />
      <LandingPage data={data.landingPagesJson} />
    </React.StrictMode>
  )
}

export default withRoot(LandingPages)
export const query = graphql`
  {
    landingPagesJson {
      header {
        description
        label
        subdescription1
        subdescription2
        sublabel
        title
        icon {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
      }
      characteristic {
        description
        label
        title
        img {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
      }
      medical {
        description
        label
        title
        listImg {
          img {
            alt
            src {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
        }
      }
      mechanical {
        description
        label
        title
        img {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
      }
    }
  }
`
