import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Image from "../../common/Image";
import Background from "../../../data/landing-pages/media/headerBanner.png";

 
const useStyles = makeStyles((theme) => ({
  root:{
    background: `transparent url(${Background})`,
    backgroundSize: `cover`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `66% `,
    marginBottom:"10%",
    width: "100%",
    height: "calc(100vh - 60px)",
    position: "relative", 
    display:"flex",
    justifyContent:"space-around",
    flexDirection:"column",
    alignItems:"center",
    [theme.breakpoints.down("md")]:{
      marginBottom:"10%",
    },
    [theme.breakpoints.down("sm")]:{
      marginBottom:"10%",
    },
    [theme.breakpoints.down("xs")]:{
      marginBottom:"15%",
    },
  },
  icon:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    width:"100%",
  },
  contenIcon:{
    width:"260px",
    height:"170px",
    [theme.breakpoints.down("lg")]:{
      width:"300px",
      height:"195px",
    },
    [theme.breakpoints.down("md")]:{
      width:"600px",
      height:"390px",
    },
    [theme.breakpoints.down("sm")]:{
      width:"300px",
      height:"195px",
    },
    [theme.breakpoints.down("xs")]:{
      width:"200px",
      height:"130px",
     },
  },
  title: {
    color:"#fff",
    fontWeight: "bold",
    textAlign:"center",
    padding:"15vh 0 7vh 0",
    [theme.breakpoints.down("md")]:{
      fontSize:"4em",
    },
    [theme.breakpoints.down("sm")]:{
      fontSize:"3.5em",
    },
    [theme.breakpoints.down("xs")]:{
      fontSize:"2em",
    },
  },
  text:{
    color:"#fff",
    textAlign:"center",
  },
  description:{
    color:"#fff",
    fontSize:"2em",
    marginBottom:"10px",
    [theme.breakpoints.down("md")]:{
      fontSize:"2em",
    },
    [theme.breakpoints.down("sm")]:{
      fontSize:"1.8em",
    },
    [theme.breakpoints.down("xs")]:{
      fontSize:"1.3em",
    }
  },
  sub:{
    display:"flex",
    justifyContent:"center",
  },
  subdescription:{
    color:"yellow",
    margin:"5%",
    fontSize:"2.2em",
    [theme.breakpoints.down("md")]:{
    
    },
    [theme.breakpoints.down("sm")]:{
      
    },
    [theme.breakpoints.down("xs")]:{
     
    }
  }
}));


export default function Header({ data }) {
  const classes = useStyles();
  return ( 
    <div className={classes.root} id="header">
          <div>
            <Typography  variant="h2" className={classes.title} >
            {data.title}
            </Typography>
            <div className={classes.text}>
              <Typography variant="h4" className={classes.description}>
                {data.description}
              </Typography>
              <div className={classes.sub}>
                  <Typography variant="h6" className={classes.subdescription}>
                    {data.subdescription1}
                  </Typography>
                  <Typography variant="h6" className={classes.subdescription}>
                    {data.subdescription2}
                  </Typography>
              </div>
            </div>
            <div className={classes.icon}>
              <Image className={classes.contenIcon} {...data.icon} />
            </div>
          </div>
    </div>
   
  )
}
